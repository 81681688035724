import React, { Component } from 'react';
import './App.css';
import GeoMap from './GeoMap';
import total from './assests/total.svg';
import cured from './assests/cured.svg';
import death from './assests/death.svg';
import active from './assests/active.svg';
import critical from './assests/critical.svg';
import Card from './components/Card';
import * as _ from  'lodash';
import  TableComponent  from './components/TableComponent';
import { ThreeHorseLoading } from 'react-loadingg';
import Navbar from './components/Navbar';
import { lightblue } from 'color-name';
import MessageAlert from './components/MessageAlert';
import CountryStatsDetailView from './components/CountryStatsDetailView';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list : [],
            allStats : {},
            isLoading : true,
            selectedCountry : '',
            cards : [
                {title : 'Total', icon : total, field : 'cases.total', style:{color : '#32276f', background: '#e9f5ff'}},
                {title : 'Active', icon : active, field : 'cases.active', style:{color : '#4848d0', background: '#eaeaff'}},
                {title : 'Deaths', icon : death, field : 'deaths.total', style:{color : 'red', borderRadius : '1px solid #FFCBBB', background : '#FFCCCB'}},
                {title : 'Critical', icon : critical, field : 'cases.critical', style:{color : 'orange', background: '#fff7e8'}},
                {title : 'Cured', icon : cured, field : 'cases.recovered', style:{color : 'green', background: '#d7ffd7'}},
            ],
            headers : [
                {title : 'Country'},
                {title : 'Total'},
                {title : 'Active'},
                {title : 'Critical'},
                {title : 'Cured'},
                {title : 'Deaths'}
            ]
        }
    }
    componentDidMount() {
        fetch("https://covid-193.p.rapidapi.com/statistics", {
            "method": "GET",
            "headers": {
                "x-rapidapi-host": "covid-193.p.rapidapi.com",
                "x-rapidapi-key": "94fe8c2662mshc6f05b513b87767p15eef9jsn61053346c8c9"
            }
        })
        .then(response => response.json())
        .then(res => this.setState({
            isLoading : false,
            list : res.response.filter(row => row.country!=='All').sort((x,y)=>y.cases.total - x.cases.total),
            allStats : res.response.filter(row => row.country==='All')[0]
        }))
        .catch(err => {
            console.log(err);
        });
    };

    renderCards = () => Object.keys(this.state.allStats).length>0 && this.state.cards.map(row => {
        const count = _.get(this.state.allStats, row.field);
        return <Card title={row.title} count={count} icon={row.icon} style={row.style}/> 
    });

    renderMobileCards = () => Object.keys(this.state.allStats).length>0 && this.state.cards.map(row => {
        const count = _.get(this.state.allStats, row.field);
        return (
            <div className="col mx-1 mobile-card-shadow py-3" style={{
                ...row.style
                }}>
                <div className="col">
                    <div className="row">
                        {new Intl.NumberFormat().format(count)}
                    </div>
                    <div className="row">   
                        {row.title}
                    </div>
                </div>
            </div>
        )
    });    

    polyFillNames = (name = '') => {
        switch(name) {
            case 'S.-Korea' : return 'South Korea';
            case 'USA' : return 'US';
            case 'UK' : return 'United Kingdom';
            case 'Congo' : return 'Democratic Republic of the Congo';
            case 'Czechia' : return 'Czech Republic';
            case 'CAR' : return 'Central African Republic';
            case 'UAE' : return 'United Arab Emirates';
            default : return name.includes('-') ? name.replace('-',' ') : name;
        }
    }

    onClickCountry = (country) => this.setState({selectedCountry : country})

    render () {
        const data = [['Country', 'Total Cases'], ...(this.state.list.filter(e=>e.country!=='All').map(e=> [this.polyFillNames(e.country), e.cases.total]))];
        return (
            <>
                {this.state.isLoading ? <ThreeHorseLoading /> : 
                <>
                    <Navbar />
                    <MessageAlert />
                    <div className="container my-3">
                        <div className="row card-div responsive-cards  font-weight-bold">
                                {this.renderCards()}
                            <div className="col-lg-10 col-md-12 col-sm-10 col-xs-10" style={{marginTop : "2rem"}}>
                                {/* <GeoMap data={data}/> */}
                            </div>
                        </div>
                        <div className="my-4 mobile-cards font-weight-bold">
                            { this.renderMobileCards() }
                        </div>

                        <TableComponent headers={this.state.headers} list={this.state.list} onClickCountry={ this.onClickCountry }/>
                        {/* <CountryStatsDetailView country={this.state.selectedCountry}/> */}
                    </div>
                </>
                }
            </>
        );
    }
  
}

export default App;
