import Chart from "react-google-charts";

import React, { Component } from 'react'

export class GeoMap extends Component {
    render() {
        const { data } = this.props;
        return (
            <>
                <Chart
                    width={'1100px'}
                    height={'408px'}
                    chartType="GeoChart"
                    data={data}
                      options={{
                        legend : 'none',
                        backgroundColor: 'white',
                        colorAxis: { colors: ['#fffccc','#ffc100', '#ff9a00', '#ff7400', '#ff4d00', '#ff0000'] }
                      }}
                    // Note: you will need to get a mapsApiKey for your project.
                    // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                    mapsApiKey="AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY"
                    rootProps={{ 'data-testid': '2' }}
                    chartEvents={[
                        {
                          eventName: 'select',
                          callback: ({ chartWrapper }) => {
                            let selection = chartWrapper.getChart().getSelection();
                            if (selection.length === 1) {
                                console.log(selection)
                            }
                          },
                        },
                      ]}
                    />
            </>
        )
    }
}

export default GeoMap
