import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
  } from 'react-router-dom';
import App from '../App';
import CountryStatsDetailView from './CountryStatsDetailView';
  
const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/country/:country" component={CountryStatsDetailView} />
                <Route path="/home" component={App} />
                <Redirect to="/home" from="*" />
            </Switch>
        </BrowserRouter>
    );
};


export default Router;

