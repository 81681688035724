import React, { Component } from 'react'
import * as moment from 'moment';
import LineChart from './LineChart';

export class CountryStatsDetailView extends Component {
    constructor() {
        super();
        this.state = {
            countryStats : [],
            dataForChart : []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.country !== nextProps.country) {
            this.getCountryData(nextProps.country);
            return true;
        }
        return false;
    }

    getCountryData = (country) => {
        if(!country) {
            return false;
        }
        fetch(`https://coronavirus-monitor.p.rapidapi.com/coronavirus/cases_by_particular_country.php?country=${encodeURI(country)}`, {
            "method": "GET",
            "headers": {
                "x-rapidapi-host": "coronavirus-monitor.p.rapidapi.com",
                "x-rapidapi-key": "94fe8c2662mshc6f05b513b87767p15eef9jsn61053346c8c9"
            }
        })
        .then(response => response.json())
        .then(response => {
            this.setState(()=> {
                return {countryStats : response["stat_by_country"]}
            });
            this.getDataForGraph()
        })
        .catch(err => {
            console.log(err);
        });
    }

    getDataForGraph = () => {
        let s = new Set();
        const l = this.state.countryStats.map(e=>{
            s.add(moment(e["record_date"]).format('YYYY-MM-DD'));
            return {...e, record_date : moment(e["record_date"]).format('YYYY-MM-DD')}
        });
        this.getMaximumValues(Array.from(s), l);
    };

    getMaximumValues = (uniqueList, completeList) => {
        const list = uniqueList.map(e=> completeList.filter(l => l["record_date"]===e).sort((x,y)=>Number(x["total_cases"])>Number(y["total_cases"]))[0])
        console.log(list)
    }
    
    render() {
        return (
            <LineChart country={this.props.country}/>
        )
    }
}

export default CountryStatsDetailView
