import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



const LineChart = ({country}) => {
    const options = {
        title: {
            text: `Covid cases in ${country}`
        },
    
        // subtitle: {
        //     text: 'Source: thesolarfoundation.com'
        // },
    
        yAxis: {
            title: {
                text: 'No of Cases'
            }
        },
    
        xAxis: {
            title: {
                text: 'Dates'
            }
        },
    
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
    
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2010
            }
        },
    
        series: [{
            name: 'Installation',
            data: [43934, 52503, 57177, 69658]
        }, {
            name: 'Manufacturing',
            data: [24916, 24064, 29742, 29851]
        }],
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }
return (<div>
  <HighchartsReact
    highcharts={Highcharts}
    options={options}
  />
</div>)
}

export default LineChart;