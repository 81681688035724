import React from 'react'

function Card({count = 0 , title = '', icon = null, style = {}}) {
    return (
        <>
            <div className="card m-2 px-3 min-dimension-card custom-card-shadow col-xs-6">
                <div className="card-body">
                    <div className="row">
                        <div className="col-7">
                            <div className="row custom-heigth">
                                <h4 style={{color : style.color}}>{new Intl.NumberFormat().format(count)}</h4>
                            </div>
                            <div className="row custom-heigth">
                                <p>{title}</p>
                             </div>
                        </div>
                        <div className="col-5">
                            <img src={icon} style={{width : '45px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card
