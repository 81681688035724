import React from 'react'
import covid from '../assests/covid-main.svg';

function Navbar() {
    return (
        <nav className="navbar navbar-light bg-light" style={{height : '90px'}}>
            <a className="navbar-brand">
                <div className="row">
                    <div className="col">
                        <img src={covid} width="60" height="60" class="d-inline-block align-top main-logo" alt="main-logo" />
                    </div>
                    <div className="row">
                        <p className="dashboard-title mt-3">Covid Dashboard <br/><p className="credit-title">&nbsp;&nbsp;by Abhinand</p></p>
                    </div>
                </div>
            </a>
        </nav>
    )
}

export default Navbar
