import React, { useState, useEffect } from 'react'

function MessageAlert() {
    const messages = [
        'Wash your hands regularly for 20 seconds, with soap and water or alcohol-based hand rub.',
        'Cover your nose and mouth with a disposable tissue or flexed elbow when you cough or sneeze.',
        'Avoid close contact (1 meter or 3 feet) with people who are unwell.',
        'Avoid social gathering. A healthy looking person may also be an active carrier',
        'Stay home and self-isolate from others in the household if you feel unwell.',
        'Don\'t touch your eyes, nose, or mouth if your hands are not clean.',
        'Do not stockpile on masks.',
        'Drink plenty of water/liquids and eat nutritious food.',
        'Person suffering from Influenza like illness must be confined at home.',
        'Avoid touching surfaces usually used by public (Railing, door, gates etc)',
        'Symptoms may take upto 14 days to show up. '
    ];
    const [message, setMessage] = useState('');

    useEffect(() => updateMessage(), []);

    const updateMessage = () => {
        messages.unshift(messages.pop())
        setMessage(messages[0]);
        setTimeout(() => {
            updateMessage();
        },7000)
    }


    return (
        <div className="container-fluid row m-auto" style={{background : '#aaa9f1'}}>
            <p className="text-center w-100 mt-2 font-weight-bold">{message}</p>
        </div>
    )
}

export default MessageAlert
