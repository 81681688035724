import React, { Component } from 'react'
import * as _ from  'lodash';

export class TableComponent extends Component {

    renderHeaders = () => (
      <thead>
        <tr>
          {this.props && this.props.headers && this.props.headers.map(row=>(
            <th style={row.style} className={row.title === 'Country' ? 'country-cell' : ''}><strong>{row.title}</strong></th>
          ))}
        </tr>
      </thead>
    );

    renderRows = () => {
        return (<tbody> 
          {this.props && this.props.list && this.props.list.map(row => (
            <tr key={row.country} onClick={ () => this.props.onClickCountry(row.country)}>
            <td style={{wordBreak : 'break-all'}} className="country-cell">{row.country}</td>
            <td>
              <tr>{new Intl.NumberFormat().format(_.get(row, 'cases.total'))}</tr>
              {_.get(row, 'cases.new') ? <tr style={{color: 'red'}} className="additional-count">+{new Intl.NumberFormat().format(_.get(row, 'cases.new'))}</tr> : ''}
            </td>
            <td>{new Intl.NumberFormat().format(_.get(row, 'cases.active'))}</td>
            <td>{new Intl.NumberFormat().format(_.get(row, 'cases.critical'))}</td>
            <td>{new Intl.NumberFormat().format(_.get(row, 'cases.recovered'))}</td>
            <td>
              <tr>{new Intl.NumberFormat().format(_.get(row, 'deaths.total'))}</tr>
              {_.get(row, 'deaths.new') ? <tr style={{color: 'red'}}  className="additional-count">+{new Intl.NumberFormat().format(_.get(row, 'deaths.new'))}</tr> : ''}
            </td>
            </tr>
            ))}             
        </tbody>)
           
    }

    render() {
        return (
            <table class="table table-responsive mt-2">
              { this.renderHeaders() }
              { this.renderRows() }
            </table>
        )
    }
}

export default TableComponent
